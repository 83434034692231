<template>
    <div>
        <mainheader></mainheader>
        <mainbox></mainbox>
        <section>
            <div class="container">
                <div class="article-container">
                    <div class="article-wrapper">

                        <span class="article-title">{{ translation.translate('article2', 'title') }}</span>
                        <br> 
                        <div class="article-content">
                            <img class="article-small-logo" src="../../assets/images/svg/edo2.svg" />
                            <span class="artice-small-text"> {{ translation.translate('article2', 'article') }}</span>

                        </div>
                        <img class="article-main-logo" src="../../assets/images/svg/articles/article2.svg" />
                        <p class="article-text">{{ translation.translate('article2', 'text-content-1') }} </p>
                        <div class="article-questions-btn-container">
                            <button class="article-questions-btn" @click="redirectToPetition()">{{ translation.translate('article2', 'questions-btn')
                            }}</button>
                        </div>
                        <p class="article-text">{{ translation.translate('article2', 'text-content-2') }} </p>
                        <div class="paragraph">
                            <span class="article-text">1.</span> 
                            <p class="article-text"> <span class="article-text-bold">{{ translation.translate('article2', 'bold-text-content-1') }}</span> {{ translation.translate('article2', 'text-content-3') }} </p>
                        </div>
                        <div class="paragraph">
                            <span class="article-text">2.</span> 
                            <p class="article-text"> <span class="article-text-bold">{{ translation.translate('article2', 'bold-text-content-2') }}</span> {{ translation.translate('article2', 'text-content-4') }} </p>
                        </div>
                        <div class="paragraph">
                            <span class="article-text">3.</span> 
                            <p class="article-text"> <span class="article-text-bold">{{ translation.translate('article2', 'bold-text-content-3') }}</span> {{ translation.translate('article2', 'text-content-5') }} </p>
                        </div>
                        <p class="article-text">
                            {{ translation.translate('article2', 'text-content-6') }}
                        </p>
                    </div>
                </div>
                <div class="article-footer-container">
                    <div class="article-footer">
                        <p class="article-footer-text">
                            {{ translation.translate('article2', 'footer-text') }}
                            <span class="article-text-bold">{{ translation.translate('article2', 'footer-bold-text')
                            }}</span>
                        </p>

                        <div class="article-questions-btn-container">
                            <button class="article-questions-btn" @click="redirectToPetition()">{{ translation.translate('article2', 'footer-btn')
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <mainfooter></mainfooter>
    </div>
</template>


<script>
import translate from '../../../translation/translate'
import mainbox from "./../mainbox.vue";
import mainheader from "./../mainheader.vue";
import mainfooter from "./../mainfooter.vue";

export default {
    name: 'article2',
    data() {
        return {
            translation: translate,

        }
    },
    components: {
        mainheader,
        mainbox,
        mainfooter
    },
    methods: {
        redirectToPetition() {
            let p = document.getElementById('hiddenP');
            if (!p) {
                p = document.createElement("p");
                p.setAttribute("type", "hidden");
                p.style.display = 'none';
                p.setAttribute("id", "hiddenP");
                document.body.appendChild(p);
            }
            p.innerHTML = '2';
            this.$router.push({name: 'petition'})
        }
    },
    metaInfo() {
    return {
      title: "Показываем, как правильно создавать произвольные документы и на что нужно обратить внимание",
      meta: [
        {
          name: "description",
          content:
            "Статьи СЭД Faktura.EDO",
        },
      ],
    };
  },
}
</script>

<style scoped>

.paragraph {
    display: flex;
}
.paragraph> p {
    margin-left: 10px;
}
</style>